import React from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";

export default function PrivacyPolicy() {
  return (
    <>
      <SEO title="Kebijakan Privasi - Rupiah.trade" />
      <Nav />
      <div
        data-aos="fade-up"
        className="px-4 pt-8 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8"
      >
        <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-slate-900 sm:text-4xl md:mt-16">
          Kebijakan Privasi
        </h1>
        <div className="prose lg:prose-lg prose-slate max-w-full mt-8 ">
          <div>
            <p>
              Jika Anda memerlukan informasi lebih lanjut atau memiliki
              pertanyaan apapun tentang kebijakan privasi kami, jangan ragu
              untuk menghubungi kami melalui email di{" "}
              <a href="mailto:ismail17januari@gmail.com">
                ismail17januari@gmail.com
              </a>
              . Dengan menggunakan Rupiah.trade Anda setuju dengan kebijakan
              privasi ini.
            </p>
            <p>
              Di Rupiah.trade, privasi pengunjung kami sangatlah penting bagi
              kami. Dokumen kebijakan privasi ini menjelaskan jenis-jenis
              informasi pribadi yang diterima dan dikumpulkan oleh
              https://rupiah.trade/ selanjutnya Rupiah.trade dan bagaimana ia
              digunakan.
            </p>

            <h2 id="pengumpulan-data">I. Pengumpulan Data</h2>
            <ol>
              <li>
                Kami mengumpulkan Informasi Yang Diperoleh (termasuk Data
                Pribadi) agar Aplikasi dapat digunakan sesuai dengan maksud dan
                tujuannya serta untuk menunjang efisiensi kinerja dan
                meningkatkan kualitas Aplikasi dan layanan kami.
              </li>
              <li>
                Saat anda membuat pesanan, kami mengumpulkan data pesanan anda,
                seperti nama, email, nomor telepon ke server kami. Setelah anda
                menyelesaikan pembayaran lewat PayPal, kami menyimpan data
                transaksi anda dan informasi dasar dari akun PayPal anda.
              </li>
              <li>
                Informasi Yang Diperoleh meliputi Data Pribadi dan informasi
                lainnya yang Anda berikan secara langsung maupun yang terkumpul
                secara otomatis karena Anda mengakses dan/atau menggunakan situs
                web ini.
              </li>
              <li>
                Informasi Yang Diperoleh yang Anda berikan secara langsung
                kepada Kami meliputi Data Pribadi yang Anda berikan ketika
                proses pendaftaran Akun atau pemeliharaan data sehubungan dengan
                Akun.
              </li>
              <li>
                Kami tidak akan membagikan data yang berhubungan dengan privasi
                Anda ke pihak yang tidak berkaitan dengan layanan kami Informasi
                Yang Diperoleh yang terkumpul secara otomatis ketika Anda
                mengakses dan/atau menggunakan situs web ini meliputi alamat
                protokol internet (internet protocol address), informasi untuk
                masuk sistem atau aplikasi (login information), lokasi geografis
                (geolocation), sistem operasi (operating system) dan versi
                peramban (browser version) dari perangkat elektronik yang Anda
                gunakan untuk mengakses situs web ini, lalu lintas data (data
                traffic), rekaman situs dan data komunikasi lainnya.
              </li>
              <li>
                Untuk dapat menggunakan situs web ini, Kami berhak meminta
                Pengguna untuk mengaktifkan cookies atau secara otomatis
                mengaktifkan cookies selama Anda mengakses dan/atau menggunakan
                situs web ini. Dalam hal Pengguna menolak untuk mengaktifkan
                cookies, akses atau penggunaan Anda terhadap situs web ini
                dan/atau fungsi atau bagian tertentu pada situs web ini dapat
                terpengaruh atau terhenti.
              </li>
            </ol>

            <h2 id="file-file-log">II. File-file log</h2>
            <p>
              Seperti kebanyakan situs Web lain, Rupiah.trade menggunakan
              file-file log. Informasi di dalam file-file log meliputi IP
              address, jenis browser, Internet Service Provider (ISP), stamp
              tanggal/waktu, halaman referring/keluar, dan jumlah klik untuk
              menganalisis kecenderungan, mengelola situs, melacak pergerakan
              pengguna di sekitar situs, dan mengumpulkan informasi demografis.
              IP address, dan informasi lainnya yang tidak terkait dengan
              informasi apapun yang bersifat pribadi.
            </p>
            <h2 id="cookies-dan-web-beacons">III. Cookies dan Web Beacons</h2>
            <p>
              Seperti situs web lain, Rupiah.trade menggunakan ‘cookies’.
              Cookies ini digunakan untuk menyimpan informasi termasuk
              preferensi pengunjung, dan halaman-halaman di situs web yang
              diakses atau dikunjungi pengunjung. Informasi ini digunakan untuk
              mengoptimalkan pengalaman pengguna dengan menyesuaikan konten
              halaman web kami berdasarkan jenis browser pengunjung dan
              informasi lainnya.
            </p>

            <h2 id="penggunaan-cookies-pihak-ketiga">
              IV. Penggunaan Cookies Pihak Ketiga
            </h2>
            <p>
              Beberapa layanan pihak ketiga mungkin menggunakan cookies dan web
              beacon di situs kami.
            </p>
            <ol>
              <li>Google Analytics</li>
              <li>Google reCAPTCHA</li>
              <li>PayPal</li>
            </ol>
            <p>
              Rupiah.trade tidak memiliki akses atau kontrol terhadap cookies
              yang digunakan oleh pengiklan pihak ketiga.
            </p>
            <p>
              Anda harus berkonsultasi dengan kebijakan privasi masing-masing
              dari server iklan pihak ketiga untuk informasi lebih rinci tentang
              praktek-praktek mereka serta untuk mendapatkan petunjuk tentang
              cara pilihan keluar dari praktek-praktek tertentu. Kebijakan
              privasi Rupiah.trade.com tidak berlaku untuk itu, dan kami tidak
              dapat mengontrol kegiatan, pengiklan atau situs web lain.
            </p>
            <p>
              Jika Anda ingin menonaktifkan cookies, Anda dapat melakukannya
              melalui pilihan browser tersendiri. Informasi lebih lanjut tentang
              pengelolaan cookies dengan browser web tertentu dapat ditemukan di
              masing-masing situs web browser.
            </p>

            <h2 id="pengungkapan-informasi">
              V. Pemberian atau Pengungkapan Informasi yang Diperoleh
            </h2>

            <p>
              Kami mungkin dapat mempekerjakan perusahaan pihak ketiga dan
              individu karena alasan berikut:
            </p>
            <ol>
              <li>Untuk memfasilitasi Layanan kami; atau</li>
              <li>Untuk menyediakan Layanan atas nama kami; atau</li>
              <li>Untuk melakukan layanan terkait Layanan; atau.</li>
              <li>
                Untuk membantu kami dalam menganalisis bagaimana Layanan kami
                digunakan.
              </li>
            </ol>
            <p>
              Kami akan melakukan upaya yang wajar untuk menghapus Data Pribadi
              yang tidak perlu dikaitkan dengan Pengguna sebelum memberikan
              dan/atau mengungkapkan Informasi Yang Diperoleh (termasuk Data
              Pribadi) kepada pihak-pihak sebagaimana disebutkan di atas.
            </p>
            <p>
              Kami dapat menghilangkan bagian-bagian tertentu dari Data Pribadi
              yang dapat mengidentifikasikan Pengguna sehingga menjadi data
              anonim, dan mengungkapkan data anonim tersebut kepada pihak
              ketiga. Kami juga dapat mengkombinasikan Data Pribadi dengan
              informasi-informasi lain sedemikan rupa sehingga informasi
              tersebut tidak lagi terasosiasi dengan Pengguna, dan kemudian
              memberikan dan/atau mengungkapkan informasi yang telah
              dikombinasikan tersebut kepada pihak ketiga.
            </p>
            <p>
              Kami ingin memberi tahu pengguna Layanan ini bahwa pihak ketiga
              ini memiliki akses ke Informasi Pribadi Anda. Alasannya adalah
              untuk melakukan tugas yang diberikan kepada mereka atas nama kami.
              Namun, mereka berkewajiban untuk tidak mengungkapkan atau
              menggunakan informasi untuk tujuan lain apa pun.
            </p>

            <h2>VI. Keamanan</h2>

            <ol>
              <li>
                Kerahasiaan atas Data Pribadi merupakan hal yang sangat penting
                bagi Kami. Kami berupaya dan akan selalu mengambil
                langkah-langkah yang secara wajar diperlukan untuk melindungi
                keamanan Informasi Yang Diperoleh (termasuk Data Pribadi),
                antara lain melalui penggunaan teknologi untuk mengacak jalur
                komunikasi antar komputer sehingga tidak dapat dibaca oleh pihak
                lain seperti Secure Socket Layer (SSL). Identitas (ID) Pengguna
                dan kata sandi (password) digunakan untuk autentikasi akses
                masuk (login) ke Akun Anda. Namun, Kami tidak dapat sepenuhnya
                menjamin bahwa sistem Kami benar-benar tidak dapat ditembus oleh
                virus, malware, gangguan, peretasan, pelanggaran hukum atau
                akses tidak sah oleh pihak ketiga.
              </li>
              <li>
                Anda dilarang memberikan atau mengungkapkan identitas (ID) Anda
                dan/atau kata sandi (password) Anda kepada pihak lain manapun
                dan harus selalu menjaga keamanan perangkat elektronik yang Anda
                gunakan.
              </li>
              <li>
                Kami tidak akan pernah meminta Anda untuk menyerahkan,
                mengungkapkan dan/atau menyebarkan informasi personal atau
                keamanan Anda, termasuk Akun atau identitas (ID), termasuk
                melalui surat elektronik (e-mail). Jika Anda menerima permintaan
                demikian yang mengatas-namakan Kami, mohon untuk tidak merespon
                dan segera meneruskan permintaan demikian kepada Kami ke alamat
                surat elektronik kami serta menghapus surat elektronik tersebut.
              </li>
            </ol>

            <hr />

            <p>
              Kebijakan Privasi ini hanya berlaku untuk aktivitas online kami
              dan berlaku untuk pengunjung situs web kami sehubungan dengan
              informasi yang mereka bagikan dan / atau kumpulkan di
              Rupiah.trade. Kebijakan ini tidak berlaku untuk informasi apa pun
              yang dikumpulkan secara offline atau melalui saluran selain dari
              situs web ini. Persetujuan
            </p>
            <p>
              Dengan menggunakan situs web kami, Anda dengan ini menyetujui
              Kebijakan Privasi kami dan menyetujui{" "}
              <a href="https://rupiah.trade/tos/">
                <b>Syarat dan Ketentuan pengggunaan layanan Rupiah.trade</b>
              </a>
              .
            </p>

            <p>Diperbarui pada: 03/04/2022</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
